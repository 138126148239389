import React, { useEffect } from "react";
import Sidebar from "./Sidebar";
import Header from "./Header";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { setStorePatientTabState } from "../../redux/actions/adminActions";

const AuthLayout = (props) => {
    const {children} = props;
    const patientTab = useSelector((state)=>state?.adminReducers?.patientTab)
    const dispatch = useDispatch()
    useEffect(()=>{
        AuthRedux()
    },[])
    const AuthRedux = () =>{
        if(patientTab === undefined){
            dispatch(setStorePatientTabState("SUMMARY", "SUMMARY"));
        }
    }
    return(
    <section className="adminmain">
        <Sidebar />
        <div className={"panelwrapper active"}>
            <Header />
            <div className="panelContentwrapper">
               <div className={`panel_children ${props?.layoutClassName}`}>
                {children}
               </div>
            </div>
        </div>
    </section>
    )
}

export default AuthLayout;
AuthLayout.propTypes = {
    layoutClassName: PropTypes.any
}
AuthLayout.defaultProps = {
    layoutClassName:""
}