import React from "react";
import { Link } from "react-router-dom";

const BackButton = (props) => {
    const { target_url, title } = props;

    return(<div className="header_back">
        <Link to={target_url} className="header_backbtn"><i className="bi bi-arrow-left"></i> &nbsp; {title}</Link>
    </div>)
};

export default BackButton;