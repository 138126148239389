import React from "react";
import ModelIcons from "../../assets/img/Model_icons.png";
import BrandIcons from "../../assets/img/brand-image.png";
import { Scrollbars } from 'react-custom-scrollbars-2';
import AuthLayout from "../Shared/AuthLayout";

const Dashboard = () => {
    return(
        <AuthLayout>
            <section className="dashboard_wrapper">
                {/* {/* <Scrollbars 
                    style={{ height: "calc(100vh - 100px)"}} 
                    className="ScrollbarsWrapper"
                    renderView={props => <div {...props} className="view"/>}
                >
                    <div className="column_count_colus">
                        <div className="dashColswe">
                            <div className="dashIcon" style={{ backgroundColor: "rgba(255, 201, 108, 0.1)", color: "#ffc96c" }}>
                                <i className="bi bi-people-fill"></i>
                            </div>
                            <h1>10</h1>
                            <h4>Total Users</h4>
                        </div>
                        <div className="dashColswe">
                            <div className="dashIcon" style={{ backgroundColor: "rgb(59 156 255 / 10%)", color: "#017bf6" }}>
                                <i className="bi bi-archive-fill"></i>
                            </div>
                            <h1>220</h1>
                            <h4>Total Prooducts</h4>
                        </div>
                        <div className="dashColswe">
                            <div className="dashIcon" style={{ backgroundColor: "rgba(30, 190, 44, 0.1)",color: "#1ebe2c" }}>
                                <i className="bi bi-truck"></i>
                            </div>
                            <h1>20</h1>
                            <h4>Total Suppliers</h4>
                        </div>
                        <div className="dashColswe">
                            <div className="dashIcon" style={{ backgroundColor: "rgba(177, 133, 255,0.1)",color: "#b185ff" }}>
                                <i className="bi bi-cart-fill"></i>
                            </div>
                            <h1>32</h1>
                            <h4>Total Carts</h4>
                        </div>
                        <div className="dashColswe">
                            <div className="dashIcon" style={{ backgroundColor: "rgba(143, 82, 161,0.1)",color: "#8f52a1" }}>
                                <img src={ModelIcons} alt="ModelIcons" />
                            </div>
                            <h1>32</h1>
                            <h4>Total Model</h4>
                        </div>
                        <div className="dashColswe">
                            <div className="dashIcon" style={{ backgroundColor: "rgba(177, 133, 255,0.1)",color: "#b185ff" }}>
                                <img src={BrandIcons} alt="BrandIcons" />
                            </div>
                            <h1>32</h1>
                            <h4>Total Brands</h4>
                        </div>
                    </div>
                </Scrollbars> */}
            </section>
        </AuthLayout>
    )
}

export default Dashboard;