/* eslint-disable */
import React from "react";
import EmptySpaceFieldValid from "./EmptySpaceFieldValid";
import ExportIcon from "../../assets/img/export_icon.png";
import InputGroup from "./InputGroup";

const DataTableFilter = (props) => {
    const { filterType, buttonVisible, userSearch, ButtonSet, setUserSearch, userType, userLimit, setUserLimit, ClearSearchFiled, SelectUserTypeFnct } = props;

    return(<div className="card-header-New">
        {userSearch !== undefined && (
            <React.Fragment>
                <InputGroup
                    formClassName={"searchBoxwithbtn"}
                    inline
                    rightLabel={userSearch === ""? <i className="bi bi-search" /> :<i className="bi bi-x-circle"></i>}
                    placeholder={`Search ${filterType}...`}
                    value={userSearch}
                    onChange={(e)=>setUserSearch(e.target.value)}
                    rightIconClick={userSearch === "" ? null : ClearSearchFiled}
                />
            </React.Fragment>
        )}
        <div className="card-header-right">
            {userType !== undefined && (<select className="form-control activestatusselet" value={userType} onChange={(e) => SelectUserTypeFnct(e)}>
                <option value="all">All</option>
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
            </select>)}
            <select className="form-control userLimitselect" value={userLimit} onChange={(e) => setUserLimit(e.target.value)}>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
                <option value="40">40</option>
                <option value="50">50</option>
                <option value="60">60</option>
                <option value="70">70</option>
                <option value="80">80</option>
                <option value="90">90</option>
                <option value="100">100</option>
            </select>
            {buttonVisible && (<button className="btn head_cmn_btn" onClick={ButtonSet?.function}>
                <i className="bi bi-plus-circle"></i>
                {ButtonSet?.buttonTitle}
            </button>)}
            {filterType === "Product" && (<button className="btn cmn_export_btn" >
                <img src={ExportIcon} alt="ExportIcon" />
                Export
            </button>)}
        </div>
    </div>)
}

export default DataTableFilter;