import React, { useState, useEffect } from "react";
import { Scrollbars } from 'react-custom-scrollbars-2';
import {Nav, Tab, Tabs, NavLink } from "react-bootstrap";
import AuthLayout from "../Shared/AuthLayout";
import HeaderPatient from "./HeaderPatient";
import { Link } from "react-router-dom";
import { App_url } from "../Shared/constant";
import Icon from "../Common/Icon";
import SideNav from "../Common/SideNav";
import { setStorePatientTabState } from "../../redux/actions/adminActions";
import { useDispatch, useSelector } from "react-redux";

const PatientDetail = (props) => {
    const { patientTab } = useSelector((state) => state.adminReducers);
    const Dispatch = useDispatch();
    const SideBarMenu = [
        {
            title:"Summary",
            to: "Summary",
            Icon:<i className="bi bi-speedometer2"/>,
        },
        {
            title:"Patient Calendar",
            to: "PatientCalendar",
            Icon:<i className="bi bi-calendar2-check"/>,
        },
        {
            title:"Diagnosis",
            to: "Diagnosis",
            Icon:<i className="bi bi-clipboard2-plus"/>,
        },
        {
            title:"Vitals",
            to: "Vitals",
            Icon:<Icon className="VitalSigns "/>,
        },
        {
            title:"Treatment Plans",
            to: "TreatmentPlans",
            Icon:<i className="bi bi-people"/>,
        },
        {
            title:"Medications",
            to: "Medications",
            Icon:<i className="bi bi-file-earmark-text"/>,
        },
    ]
    return(
        <AuthLayout {...props} layoutClassName={"p-0"}>
            <section className="dashboard_wrapper">
                <div className="patient-layout layout-tabs p-0">
                   <Tabs defaultActiveKey="all-patients"  className="layout-tab-content" >
                        <Tab eventKey="all-patients" title="ML07012000F-A Min L Li" tabClassName="primary">
                            <div className="all-patients">
                                <HeaderPatient  />
                                <div className="d-flex content-container justify-content-start w-100">
                                    <aside className="tab-sidebar">
                                        <Scrollbars
                                            style={{ height: "100%" }} 
                                            className="ScrollbarsWrapper"
                                            renderView={props => <div {...props} className="view"/>}
                                        >
                                            <Nav className="flex-column flex-column-tab-set">
                                                {SideBarMenu.map((elm, index) => {
                                                    return(<li className={patientTab === elm?.to ? `nav-link active` : `nav-link`} key={index} onClick={() => Dispatch(setStorePatientTabState(elm?.to))}>
                                                        {elm.Icon}
                                                        <span>{elm?.title}</span>
                                                    </li>)
                                                })}
                                            </Nav>
                                        </Scrollbars>
                                    </aside>
                                    <section className="patient-details patient-details-tabss">
                                        <Tabs className="layout-tab-content" >
                                            {patientTab === "Summary" && (<Tab eventKey="summary" title="Summary" tabClassName="primary">
                                                Summary no found
                                            </Tab>)}
                                            {patientTab === "PatientCalendar" && (<Tab eventKey="PatientCalendar" title="Patient Calendar" tabClassName="primary">
                                                Patient calendar no found
                                            </Tab>)}
                                            {patientTab === "Diagnosis" && (<Tab eventKey="Diagnosis" title="Diagnosis" tabClassName="primary">
                                                Diagnosis no found
                                            </Tab>)}
                                            {patientTab === "Vitals" && (<Tab eventKey="Vitals" title="Vitals" tabClassName="primary">
                                                Vitals no found
                                            </Tab>)}
                                            {patientTab === "TreatmentPlans" && (<Tab eventKey="TreatmentPlans" title="Treatment Plans" tabClassName="primary">
                                                Treatment plans no found
                                            </Tab>)}
                                            {patientTab === "Medications" && (<Tab eventKey="Medications" title="Medications" tabClassName="primary">
                                                Medications no found
                                            </Tab>)}
                                        </Tabs>
                                    </section>
                                </div>
                            </div>
                        </Tab>
                    </Tabs>
                </div>
            </section>
        </AuthLayout>
    )
}

export default PatientDetail;