import React, { useEffect, useState, createContext } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import '../src/assets/css/style.css';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthLayout from './components/Shared/AuthLayout';
import GuestLayout from './components/Shared/GuestLayout';
import Dashboard from './components/Dashboard';
import { App_url } from './components/Shared/constant';
import Patient from './components/Patient';
import AddEditPatient from './components/Patient/AddEditPatient';
import PatientDetail from './components/PatientDetail';
import Login from "./components/Login";
import ForgotPassword from "./components/ForgotPassword";
import ResetPassword from "./components/ResetPassword";
export const WebSocketContext = createContext();
window.axios = axios;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

function App(props) {
  const Dispatch = useDispatch();
  const { accessToken, is_login, clearCache } = useSelector((state) => state.adminReducers);
  const [ GetWEBSocket, setGetWEBSocket ] = useState("");

  return (
    <div className="wrapper">
        <BrowserRouter>
          <Routes>
            <Route path={App_url.Login} element={<GuestLayout><Login /></GuestLayout>} />
            <Route path={App_url.ForgotPassword} element={<GuestLayout><ForgotPassword /></GuestLayout>} />
            <Route path={`${App_url.ResetPassword}/:roomId`} element={<GuestLayout><ResetPassword /></GuestLayout>} />
            <Route path={App_url.Dashboard} element={<Dashboard />} />
            <Route path={App_url.Patient} element={<Patient />} />
            <Route path={App_url.PatientDetails} element={<PatientDetail />} />
            <Route path={App_url.AddPatient} element={<AddEditPatient />} />
            <Route path={`${App_url.EditPatient}/:roomId`} element={<AddEditPatient />} />
            <Route path={"*"} element={<Dashboard />} />
          </Routes>
        </BrowserRouter>
        <ToastContainer />
    </div>
  );
}

export default App;
