/* eslint-disable */
import React, { useState, useContext, useEffect } from "react";
import { Modal, Button, Form } from 'react-bootstrap';
import CommonLogout from "./CommonLogout";
import { DeleteUser, editUserList } from "../../redux/actions/adminActions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BtnLoader from "./BtnLoader";
import InputField from "./InputField";
import EmptySpaceFieldValid from "./EmptySpaceFieldValid";
import CheckValid from "./CheckValid";
import AddEditSupplier from "../Supplier/AddEditSupplier";

const CommonModel = (props) => {
    const [ loader, setLoader ] = useState(false);
    const [ inputValue, setInputValue ] = useState({ 
        BrandName: "", ModelName: ""
    });
    const { BrandName, ModelName } = inputValue;
    const Dispatch = useDispatch();
    const { showModal, setShowModal, setCurrentPage } = props;
    const { accessToken, brandlistall, device_id } = useSelector((state) => state.adminReducers);
    const [ selectBrand, setSelectBrand ] = useState("");

    // error message
    const [ errorBrandName, setErrorBrandName ] = useState("");
    const [ errorModelName, setErrorModelName ] = useState("");
    const [ errorSelectBrand, setErrorSelectBrand ] = useState("");

    // close modal
    const handleClose = () => {
        setShowModal({...showModal, open: false});
    };

    // Textfield common onchange function
    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputValue((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    useEffect(() => {
        if(showModal?.subtitle === "Edit Brand") {
            setInputValue({...inputValue, BrandName: showModal?.Data?.name });
        } else if(showModal?.subtitle === "Edit Model") {
            setInputValue({...inputValue, ModelName: showModal?.Data?.name });
            setSelectBrand(showModal?.Data?.brand_id);
        }
    }, [ showModal ])

    // select brand
    const selectBrandChange = (e) => {
        if(e.target.value) {
            setSelectBrand(e.target.value);
            CheckValid(e.target.value, {type: 'Select Brand', error: setErrorSelectBrand});
        } else {
            setSelectBrand("");
            CheckValid(e.target.value, {type: 'Select Brand', error: setErrorSelectBrand});
        }
    };

    const CommonFunction = async () => {
        
    };

    // When editing API calling, at that time list API calling.
    const CommonListUpdate = (url) => {
        let param = { 
            "transmit": "broadcast", 
            "url": url,
            "request" : { 
                "filter" : "all", 
                "limit" : 10, 
                "page_no" : 1, 
                "search" : "", 
            }
        };
        // wsSend_request(websocket, param);
        setCurrentPage(1);
    };

    return(<Modal className={showModal?.title === "Create Supplier" ? "commonModal addSupplierModel" : "commonModal"} show={showModal?.open} onHide={handleClose}>
        <Modal.Header closeButton>
        <Modal.Title style={{ width: showModal?.title === "Are you sure?" ? "100%" : ""}}>{showModal?.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {showModal?.modalType === "form" ? (
                <Form.Group className="mb-3" >
                    {/* <Form.Label>{showModal?.title === "Create Brand" ? "Brand Name" : "Model Name"}</Form.Label>
                    <div className="formInput">
                        <Form.Control type="text" placeholder={showModal?.title === "Create Brand" ? "Enter Brand Name" : "Enter Model Name"} />
                    </div> */}
                    {(showModal?.title === "Create Brand" || showModal?.title === "Edit Brand") ? (<InputField
                        type="text"
                        value={BrandName}
                        className={errorBrandName !== "" ? "form-control error-form" : "form-control"}
                        placeholder="Enter Brand Name"
                        label="Brand Name"
                        name="BrandName"
                        onChange={handleChange}
                        onKeyUp={(e) => CheckValid(e.target.value, {type: 'Brand Name', error: setErrorBrandName})}
                        onKeyDown={EmptySpaceFieldValid}
                        errormessage={errorBrandName}
                    />) : ((showModal?.title === "Create Model" || showModal?.title === "Edit Model")) ? (<>
                        <InputField
                            type="text"
                            value={ModelName}
                            className={errorModelName !== "" ? "form-control error-form" : "form-control"}
                            placeholder="Enter Model Name"
                            label="Model Name"
                            name="ModelName"
                            onChange={handleChange}
                            onKeyUp={(e) => CheckValid(e.target.value, {type: 'Model Name', error: setErrorModelName})}
                            onKeyDown={EmptySpaceFieldValid}
                            errormessage={errorModelName}
                        />
                        <div className="form-group">
                            <label>Brand List</label>
                            <select className="form-control" value={selectBrand} onChange={(e) => selectBrandChange(e)}>
                                <option value="">Select Brand</option>
                                {brandlistall?.map((elm, index) => {
                                    return(<option value={elm?.id} key={index}>{elm?.name}</option>)
                                })}
                            </select>
                            {errorSelectBrand !== "" && (<div className="errormendatorr"><i className="bi bi-x-circle-fill"></i>{errorSelectBrand}</div>)}
                        </div>
                    </>) : (<AddEditSupplier handleClose={handleClose} AddSupplierForPorduct={"AddSupplierForPorduct"} />)}
                </Form.Group>
            ) : (<>
                <div className="notformsection">
                    <p dangerouslySetInnerHTML={{__html: showModal?.description}}></p>
                </div>
            </>)}
        </Modal.Body>
        <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
            Close
        </Button>
        <Button variant="primary" className="addbtncmn" onClick={CommonFunction}>
            {loader && (<BtnLoader />)}
            {showModal?.button}
        </Button>
        </Modal.Footer>
    </Modal>)
}

export default CommonModel;