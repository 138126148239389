/* eslint-disable */
import { ActionTypes } from "../actions/adminActions";

export const initialData = {
    patientTab:{
        active:'',
        tab_list:[],
    },
    is_login: "yes",
    user_id: "",
    device_id: "",
    selfInfo: {is_admin:true},
    accessToken: "hisds",
    permissionList: [],
    userList: [],
    userListIdWise: {},
    brandList: {
        list: [],
        pagination: {}
    },
    modelList: {
        list: [],
        pagination: {}
    },
    brandmodelsList: {
        list: [],
        pagination: {}
    },
    supplierList: {
        list: [],
        pagination: {}
    },
    productList: {
        list: [],
        pagination: {}
    },
    brandlistall: [],
    modellistall: [],
    selectedBrandWiseModel: [],
    viewProduct: {},
    addToCartLists: [],
    clearCache: ""
};

export const adminReducers = (state = initialData, action) => {
    switch (action.type) {
        case ActionTypes.SET_STORE_PATIENT_TAB_STATE:
            // let active = action?.payload?.active;
            // let Temp_tab_list = [];
            // if(action === ""){
            //     Temp_tab_list = []
            // }else{
            //     if(action?.payload?.tab_list === ""){
            //         state?.patientTab?.tab_list?.map((item)=>Temp_tab_list?.push(item))
            //     }else{
            //         const result = state?.patientTab?.tab_list?.filter((item)=>item === action?.payload?.tab_list);
            //         if(result?.length === 0){
            //             Temp_tab_list?.push(action?.payload?.tab_list)
            //         }else{
            //             state?.patientTab?.tab_list?.filter((item)=>{
            //                 if(item !== action?.payload?.tab_list){
            //                     Temp_tab_list?.push(item)
            //                 }
            //             })
            //         }
            //     }
            // }

            // const checkIsPresent = Temp_tab_list?.filter((item)=>item === active)
            // if(checkIsPresent?.length === 0){
            //     active = "";
            //     Temp_tab_list = []
            // }
            return {
                ...state,
                patientTab: action.payload,
            };
        case ActionTypes.IS_LOGIN:
            return {
                ...state,
                is_login: action.payload,
            };
        case ActionTypes.GET_USER_ID:
            return {
                ...state,
                user_id: action.payload,
            };
        case ActionTypes.GET_DEVICE_ID:
            return {
                ...state,
                device_id: action.payload,
            };
        case ActionTypes.SELF_DETAILS:
            return {
                ...state,
                selfInfo: action.payload,
            };
        case ActionTypes.EDIT_SELF_DETAILS:
            return {
                ...state,
                selfInfo: action.payload,
            };
        case ActionTypes.GET_ACCESSTOKEN:
            return {
                ...state,
                accessToken: action.payload,
            };
        case ActionTypes.GET_USER_LIST:
            return {
                ...state,
                userList: action.payload,
            };
        case ActionTypes.GET_USER_LIST_ID_WISE: 
            const ObjectUserListId = {}
            action.payload.map((elm) => {
                ObjectUserListId[elm?.id] = elm
            });

            return {
                ...state,
                userListIdWise: ObjectUserListId,
            }
        case ActionTypes.ADD_USER_LIST:
            const OldUserlist = [...state.userList];
            OldUserlist.unshift(action.payload);
            return {
                ...state,
                userList: OldUserlist,
            }
        case ActionTypes.EDIT_USER_LIST:
            const OldUserlistx = [...state.userList];
            const UpdateUserList = OldUserlistx.map((elm) => {
                if(elm?.id === action.payload.id) {
                    return{
                        ...elm,
                        first_name : action.payload.first_name,
                        last_name : action.payload.last_name,
                        email : action.payload.email,
                        password : action.payload.password,
                        phone_code: action.payload.phone_code,
                        phone: action.payload.phone,
                        address : action.payload.address,
                        permissions : action.payload.permissions,
                        is_admin: action.payload.is_admin,
                        is_staff: action.payload.is_staff,
                        is_active : action.payload.is_active,
                    }
                } else 
                return elm;
            });
            return {
                ...state,
                userList: UpdateUserList,
            }
        case ActionTypes.DELETE_USER: 
            const OldUserListz = [...state.userList];
            const NewUpdatedUserList = [];
            OldUserListz && OldUserListz.filter((elm) => {
                if(elm?.id !== action.payload.id) {
                    return NewUpdatedUserList.push(elm);
                }
            }); 
            return {
                ...state,
                userList: NewUpdatedUserList,
            };
        case ActionTypes.GET_BRAND_LIST:
            return {
                ...state,
                brandList: {
                    list: action.payload.data === undefined ? [] : action.payload.data,
                    pagination: action.payload.pagination === undefined ? [] : action.payload.pagination
                },
            };
        case ActionTypes.GET_ALL_BRANDS_LIST:
            return {
                ...state,
                brandlistall: action.payload.data === undefined ? [] : action.payload.data,
            };
        case ActionTypes.GET_ALL_MODEL_LIST:
            return {
                ...state,
                modellistall: action.payload.data === undefined ? [] : action.payload.data,
            };
        case ActionTypes.SELECT_BRAND_GET_MODEL_LIST:
            return {
                ...state,
                selectedBrandWiseModel: action.payload.data === undefined ? [] : action.payload.data,
            };
        case ActionTypes.ADD_NEW_BRAND:
            const OldBrandlist = [...state.brandList.list];
            const OldAllBrandList = [...state.brandlistall];
            OldBrandlist.unshift(action.payload);
            OldAllBrandList.unshift(action.payload);
            return {
                ...state,
                brandList: {
                    list: OldBrandlist,
                },
                brandlistall: OldAllBrandList
            };
        case ActionTypes.EDIT_BRAND:
            const OldBrandlistx = [...state.brandList.list];
            const UpdateBrandList = OldBrandlistx.map((elm) => {
                if(elm?.id === action.payload.id) {
                    return{
                        ...elm,
                        name : action.payload.name,
                        is_active : action.payload.is_active,
                    }
                } else 
                return elm;
            });
            return {
                ...state,
                brandList: {
                    list: UpdateBrandList,
                },
            };
        case ActionTypes.DELETE_BRAND: 
            const OldBrandListz = [...state.brandList.list];
            const NewUpdatedBrandList = [];
            OldBrandListz && OldBrandListz?.filter((elm) => {
                if(elm?.id !== action.payload.id) {
                    return NewUpdatedBrandList.push(elm);
                }
            }); 
            return {
                ...state,
                brandList: {
                    list: NewUpdatedBrandList,
                },
            };
        case ActionTypes.GET_BRAND_MODELS_LIST:
            return {
                ...state,
                brandmodelsList: {
                    list: action.payload.data === undefined ? [] : action.payload.data,
                    pagination: action.payload.pagination === undefined ? [] : action.payload.pagination
                },
            };
        case ActionTypes.GET_MODEL_LIST:
            return {
                ...state,
                modelList: {
                    list: action.payload.data === undefined ? [] : action.payload.data,
                    pagination: action.payload.pagination === undefined ? [] : action.payload.pagination
                },
            };
        case ActionTypes.ADD_NEW_MODEL:
            const OldModellist = [...state.modelList.list];
            OldModellist.unshift(action.payload);
            const OldSelectBrandWiseModel = [...state.selectedBrandWiseModel]
            OldSelectBrandWiseModel.unshift(action.payload);
            return {
                ...state,
                modelList:{
                    list: OldModellist,
                },
                selectedBrandWiseModel: OldSelectBrandWiseModel
            };
        case ActionTypes.EDIT_MODEL:
            const OldModellistx = [...state.modelList.list];
            const UpdateModelList = OldModellistx.map((elm) => {
                if(elm?.id === action.payload.id) {
                    return{
                        ...elm,
                        name : action.payload.name,
                        brand_id : action.payload.brand_id,
                        is_active : action.payload.is_active,
                        added_by: action.payload.added_by
                    }
                } else 
                return elm;
            });
            return {
                ...state,
                modelList: {
                    list: UpdateModelList,
                },
            };
        case ActionTypes.DELETE_MODEL: 
            const OldModelListz = [...state.modelList.list];
            const NewUpdatedModelList = [];
            OldModelListz && OldModelListz?.filter((elm) => {
                if(elm?.id !== action.payload.id) {
                    return NewUpdatedModelList.push(elm);
                }
            }); 
            return {
                ...state,
                modelList: {
                    list: NewUpdatedModelList,
                },
            };
        case ActionTypes.GET_PERMISSION_LIST:
            return {
                ...state,
                permissionList: action.payload,
            }
        case ActionTypes.GET_SUPPLIER_LIST:
            return {
                ...state,
                supplierList: {
                    list: action.payload?.data?.data === undefined ? [] : action.payload?.data?.data,
                    pagination: action.payload.data?.pagination === undefined ? [] : action.payload.data?.pagination
                },
            };
        case ActionTypes.ADD_SUPPLIER_LIST:
            const OldSupplierlist = [...state.supplierList.list];
            OldSupplierlist.unshift(action.payload.data);
            return {
                ...state,
                supplierList:{
                    list: OldSupplierlist,
                },
            };
        case ActionTypes.GET_PRODUCT_LIST:
            return {
                ...state,
                productList: {
                    list: action.payload?.data?.data === undefined ? [] : action.payload?.data?.data,
                    pagination: action.payload.data?.pagination === undefined ? [] : action.payload.data?.pagination
                },
            };
        case ActionTypes.ADD_PRODUCT_LIST:
            const OldProductlist = [...state.supplierList.list];
            OldProductlist.unshift(action.payload);
            return {
                ...state,
                productList:{
                    list: OldProductlist,
                },
            };
        case ActionTypes.VIEW_PRODUCT_DETAILS:
            return {
                ...state,
                viewProduct: action.payload,
            };
        case ActionTypes.ADD_TO_CART:
            const OldAddToCartlist = [...state.addToCartLists];
            OldAddToCartlist.unshift(action.payload);
            return {
                ...state,
                addToCartLists: OldAddToCartlist,
            };
        case ActionTypes.REMOVE_ADD_TO_CART:
            const OldCartListz = [...state.addToCartLists];
            const NewUpdatedCartList = [];
            OldCartListz && OldCartListz?.filter((elm) => {
                if(elm?.id !== action.payload.id) {
                    return NewUpdatedCartList.push(elm);
                }
            }); 
            return {
                ...state,
                addToCartLists: NewUpdatedCartList,
            };
        case ActionTypes.ADMIN_LOGOUT:
            return {
                ...initialData,
            };
        default:
        return state;
    }
};