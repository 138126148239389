import React from 'react'
import PropTypes from "prop-types"
import { Nav,  } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'

const LinkItem = (props) =>{
    const { item, className, linkClassName } = props
    return(
        <div className="list" >
            <NavLink to={item?.to} className={`nav-link ${linkClassName} ${props?.variant} rounded-${props?.rounded}`}>
                {item.Icon}
                <span>{item?.title}</span>
            </NavLink>
        </div>
    )
}

export default function SideNav(props) {
    const SideBarMenu = props?.list;
  return (
    <Nav defaultActiveKey={props?.defaultActiveKey} className={`flex-column ${props?.className}`}>
        {props?.content}
        {SideBarMenu?.map((item, index)=>(
            <LinkItem key={index} item={item} {...props} />
        ))}
    </Nav>
  )
}

SideNav.propTypes = {
    content: PropTypes.element,
    list: PropTypes.any.isRequired,
    linkClassName: PropTypes.any,
    className: PropTypes.any,
    defaultActiveKey: PropTypes?.any,
    variant: PropTypes?.any,
    rounded: PropTypes?.any,
}
SideNav.defaultProps = {
    content: React.Fragment,
    defaultActiveKey: "",
    list: [],
    linkClassName: '',
    className: '',
    variant: 'primary',
    rounded: 1,
}