import PropTypes from 'prop-types';
import React from 'react';

export default function Icon(props) {
  if(props?.default){
    return(
      <i style={{...props?.style}}  className={`${props?.className}`} />
    )
  }
    return (
      <i style={{...props?.style}}  className={`common_icon ${props?.size} ${props?.className}`} />
    )
}
Icon.propTypes = {
  className:PropTypes?.string.isRequired,
  rounded:PropTypes?.bool,
  onClick:PropTypes?.func,
  classButton:PropTypes?.string,
  loading:PropTypes?.any,
  size:PropTypes?.any,
  default:PropTypes?.bool
}
Icon.defaultProps = {
  className:"",
  rounded:false,
  classButton:"",
  loading:false,
  default:false,
  size:"sm",
}