export const ActionTypes = {
    SET_STORE_PATIENT_TAB_STATE: "SET_STORE_PATIENT_TAB_STATE",
    IS_LOGIN: "IS_LOGIN",
    GET_USER_ID: "GET_USER_ID",
    GET_DEVICE_ID: "GET_DEVICE_ID",
    SELF_DETAILS: "SELF_DETAILS",
    EDIT_SELF_DETAILS: "EDIT_SELF_DETAILS",
    GET_ACCESSTOKEN: "GET_ACCESSTOKEN",
    ADMIN_LOGOUT: "ADMIN_LOGOUT",
    GET_PERMISSION_LIST: "GET_PERMISSION_LIST",
    GET_USER_LIST: "GET_USER_LIST",
    GET_USER_LIST_ID_WISE: "GET_USER_LIST_ID_WISE",
    ADD_USER_LIST: "ADD_USER_LIST",
    EDIT_USER_LIST: "EDIT_USER_LIST",
    DELETE_USER: "DELETE_USER",
    GET_BRAND_LIST: "GET_BRAND_LIST",
    ADD_NEW_BRAND: "ADD_NEW_BRAND",
    EDIT_BRAND: "EDIT_BRAND",
    DELETE_BRAND: "DELETE_BRAND",
    GET_MODEL_LIST: "GET_MODEL_LIST",
    ADD_NEW_MODEL: "ADD_NEW_MODEL",
    EDIT_MODEL: "EDIT_MODEL",
    DELETE_MODEL: "DELETE_MODEL",
    GET_BRAND_MODELS_LIST: "GET_BRAND_MODELS_LIST",
    GET_SUPPLIER_LIST: "GET_SUPPLIER_LIST",
    GET_PRODUCT_LIST: "GET_PRODUCT_LIST",
    ADD_SUPPLIER_LIST: "ADD_SUPPLIER_LIST",
    GET_ALL_BRANDS_LIST: "GET_ALL_BRANDS_LIST",
    GET_ALL_MODEL_LIST: "GET_ALL_MODEL_LIST",
    SELECT_BRAND_GET_MODEL_LIST: "SELECT_BRAND_GET_MODEL_LIST",
    ADD_PRODUCT_LIST: "ADD_PRODUCT_LIST",
    VIEW_PRODUCT_DETAILS: "VIEW_PRODUCT_DETAILS",
    ADD_TO_CART: "ADD_TO_CART",
    REMOVE_ADD_TO_CART: "REMOVE_ADD_TO_CART"
};

export const setStorePatientTabState = (tab) => {
    return {
        type: ActionTypes.SET_STORE_PATIENT_TAB_STATE,
        payload: tab,
    }
}

export const checkLogin = (status) => {
    return {
        type: ActionTypes.IS_LOGIN,
        payload: status,
    }
}

export const getUserId = (id) => {
    return {
        type: ActionTypes.GET_USER_ID,
        payload: id,
    }
}

export const getDeviceId = (id) => {
    return {
        type: ActionTypes.GET_DEVICE_ID,
        payload: id,
    }
}

export const DeleteUser = (user) => {
    return {
        type: ActionTypes.DELETE_USER,
        payload: user,
    }
}

export const ViewProjectDetails = (project) => {
    return {
        type: ActionTypes.VIEW_PRODUCT_DETAILS,
        payload: project,
    }
}

export const getSelfDetails = (user) => {
    return {
        type: ActionTypes.SELF_DETAILS,
        payload: user,
    }
}

export const getUserList = (list) => {
    return {
        type: ActionTypes.GET_USER_LIST,
        payload: list,
    }
}

export const getUserListIdWise = (list) => {
    return {
        type: ActionTypes.GET_USER_LIST_ID_WISE,
        payload: list,
    }
}

export const getAllBrandList = (list) => {
    return {
        type: ActionTypes.GET_ALL_BRANDS_LIST,
        payload: list,
    }
}

export const getAllModelList = (list) => {
    return {
        type: ActionTypes.GET_ALL_MODEL_LIST,
        payload: list,
    }
}

export const getBrandList = (list) => {
    return {
        type: ActionTypes.GET_BRAND_LIST,
        payload: list,
    }
}

export const addNewBrand = (brandlist) => {
    return {
        type: ActionTypes.ADD_NEW_BRAND,
        payload: brandlist,
    }
}

export const editBrand = (brandlist) => {
    return {
        type: ActionTypes.EDIT_BRAND,
        payload: brandlist,
    }
}

export const editModel = (modellist) => {
    return {
        type: ActionTypes.EDIT_MODEL,
        payload: modellist,
    }
}

export const getBrandsWiseModelList = (list) => {
    return {
        type: ActionTypes.SELECT_BRAND_GET_MODEL_LIST,
        payload: list
    }
}

export const deleteBrand = (brands) => {
    return {
        type: ActionTypes.DELETE_BRAND,
        payload: brands
    }
}

export const deleteModel = (model) => {
    return {
        type: ActionTypes.DELETE_MODEL,
        payload: model
    }
}

export const getModelList = (list) => {
    return {
        type: ActionTypes.GET_MODEL_LIST,
        payload: list,
    }
}

export const addNewModel = (brandlist) => {
    return {
        type: ActionTypes.ADD_NEW_MODEL,
        payload: brandlist,
    }
}

export const GetBrandModels = (brandsmodel) => {
    return {
        type: ActionTypes.GET_BRAND_MODELS_LIST,
        payload: brandsmodel,
    }
}

export const addUserList = (list) => {
    return {
        type: ActionTypes.ADD_USER_LIST,
        payload: list,
    }
}

export const editUserList = (list) => {
    return {
        type: ActionTypes.EDIT_USER_LIST,
        payload: list,
    }
}

export const getPermissionList = (permission) => {
    return {
        type: ActionTypes.GET_PERMISSION_LIST,
        payload: permission,
    }
}

export const getAccessToken = (token) => {
    return {
        type: ActionTypes.GET_ACCESSTOKEN,
        payload: token,
    }
}

export const getSupplierList = (list) => {
    return {
        type: ActionTypes.GET_SUPPLIER_LIST,
        payload: list,
    }
}

export const addSupplierList = (supplier) => {
    return {
        type: ActionTypes.ADD_SUPPLIER_LIST,
        payload: supplier,
    }
}

export const editSelfDetails = (user) => {
    return {
        type: ActionTypes.EDIT_SELF_DETAILS,
        payload: user,
    }
}

export const getProductList = (product) => {
    return {
        type: ActionTypes.GET_PRODUCT_LIST,
        payload: product,
    }
}

export const addProductList = (product) => {
    return {
        type: ActionTypes.ADD_PRODUCT_LIST,
        payload: product,
    }
}

export const addToCartList = (product) => {
    return {
        type: ActionTypes.ADD_TO_CART,
        payload: {
            id: new Date().getTime().toString(),
            data: product
        },
    }
}

export const RemoveCartList = (cart) => {
    return {
        type: ActionTypes.REMOVE_ADD_TO_CART,
        payload: cart,
    }
}

export const clearRedux = () => {
    return {
       type: ActionTypes.ADMIN_LOGOUT,
    }
}