import React, { useState } from 'react'
import InputGroup from '../Common/InputGroup';
import AvatarGet from '../Common/AvatarGet';
import { Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { App_url } from '../Shared/constant';

export default function HeaderPatient() {
  const [formData, setFormData] = useState();
  const Navigate = useNavigate();

  return (
    <header className='header-patient'>
        <div className='d-flex'>
            <div className='search-container'>
                <InputGroup radius={5} leftLabel={<i className='bi bi-search' />} placeholder={"Search..."} />
            </div>
            <div className='client-avatar picture-preview'>
                <AvatarGet  />
            </div>
            <div className='client-content'>
                <div className='client-title'>
                    <div className='me-2 avatar-title'>ML07012000F-A</div> | 
                    <div className='mx-2 avatar-title'>Min L Li</div>
                    <span  className="badge mx-2 px-3 text-white status-badge badge-warning badge-pill">PENDING</span>
                </div>
                <div className='client-sub-title'>
                    <span className='me-2 patient-top-bar-data'>DOB 07/01/2000</span> |
                    <span className='mx-2 patient-top-bar-data'>23 years</span> |
                    <span className='mx-2 patient-top-bar-data'>Location: Hightower Behavioral Health</span> |
                    <span className='mx-2 patient-top-bar-data'>Start/Admission Date: 06/30/2023 12:00 AM</span> 
                </div>
            </div>
        </div>
        <div className='dropdown-options'>
            <Dropdown>
                <Dropdown.Toggle>
                    Options
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item onClick={() =>  Navigate(App_url?.AddPatient)}> <i className='bi bi-pencil-square' /> Edit Patient/EOC</Dropdown.Item>
                    <Dropdown.ItemText disabled> <i className='bi bi-edit' /> Patient Status</Dropdown.ItemText>
                    <Dropdown.Item disabled> <i className='bi bi-person' /> Active</Dropdown.Item>
                    <Dropdown.Item> <i className='bi bi-person' /> Pending</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    </header>
  )
}
