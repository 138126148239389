/* eslint-disable */
import React from "react";
import { useLocation } from "react-router-dom";
import { Scrollbars } from 'react-custom-scrollbars-2';
import { App_url } from "./constant";
import Icon from "../Common/Icon";
import SideNav from "../Common/SideNav";

const Sidebar = () => {
    const RouteUrl = useLocation()?.pathname;
    // const RouteUrlEditView = "/" + RouteUrl.split("/")[1];
    // const { selfInfo } = useSelector((state) => state.adminReducers);
    const SideBarMenu = [
        {
            title:"Dashboard",
            to:App_url.Dashboard,
            Icon:<i className="bi bi-speedometer2"/>,
        },
        {
            title:"Patient",
            to:App_url.Patient,
            Icon:<i className="bi bi-people"/>,
        },
        {
            title:"Appointment",
            to:App_url.Appointment,
            Icon:<i class="bi bi-calendar-week"></i>,
        },
        {
            title:"Admission",
            to:App_url.Admission,
            Icon:<i className="bi bi-calendar2-check"/>,
        },
        {
            title:"Treatment plan",
            to:App_url.TreatmentPlan,
            Icon:<i className="bi bi-clipboard2-plus"/>,
        },
        {
            title:"Notes",
            to:App_url.Notes,
            Icon:<i className="bi bi-file-earmark-text"/>,
        },
        {
            title:"MAR",
            to:App_url.MAR,
            Icon:<Icon className="Mars" />,
        },
        {
            title:"Vital Signs",
            to:App_url.VitalSigns,
            Icon:<Icon className="VitalSigns "/>,
        },
    ]
    return(
        <div className="sidebar">
            {/* <div className="navbarHeader">
                <h1>Logo</h1>
            </div> */}
            <Scrollbars 
                style={{ height: "calc(100vh)" }} 
                renderView={props => <div {...props} className="view"/>}
                className="ScrollbarsSidebar"
            >
                <SideNav
                    list={SideBarMenu}
                    defaultActiveKey={App_url.Dashboard}
                    className="flex-column"
                    linkClassName={""}
                    content={
                        <div className="mx-3 mb-4">
                            <h5 className="logo-title">HM</h5>
                        </div>
                    }
                    variant={"primary"}
                />
            </Scrollbars>
        </div>
    )
};

export default Sidebar;