import React from 'react'
import { OverlayTrigger, Tooltip as ReactToolTip } from 'react-bootstrap';
import PropTypes from "prop-types";

export default function ToolTip(props) {
    const { children } = props;
  return (
    <OverlayTrigger
        placement={props?.placement}
        overlay={<ReactToolTip id="button-tooltip-2" style={{ position: "fixed" }}>{props?.title}</ReactToolTip>}
    >
      {children}
    </OverlayTrigger>
  )
}
ToolTip.propTypes = {
    children: PropTypes.any,
    title: PropTypes.any,
    placement: PropTypes.any
}
ToolTip.defaultProps = {
    children: "",
    title: "",
    placement:"bottom"
}