import React, { useState, useEffect } from "react";
import EmptySpaceFieldValid from "../Common/EmptySpaceFieldValid";
import CheckValid from "../Common/CheckValid";
import InputField from "../Common/InputField";
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useParams, useNavigate, useLocation } from "react-router-dom";
import CountrySelectInput from "../Common/CountrySelectInput/CountryMobileInput";
import AuthLayout from "../Shared/AuthLayout";
import BtnLoader from "../Common/BtnLoader";
import AvatarGet from "../Common/AvatarGet";
import { Spinner } from "react-bootstrap";
import { App_url } from "../Shared/constant";
import { Link } from "react-router-dom";

const AddEditPatient = (props) => {
    const Params = useParams();
    const [ inputValue, setInputValue ] = useState({ 
        Email: "", Fname: "", Lname: "", Address: "", Password: ""
    });
    const { Email, Fname, Lname, Address, Password } = inputValue;
    const [ PhoneNumber, setPhoneNumber ] = useState({
        phone_code: "1",
        phone: ""
    });
    const [ permissionGet, setPermissionGet ] = useState("");
    const [ showpassword, setshowpassword ] = useState(false);
    const [ selectedValueUser, setselectedValueUser ] = useState("");
    const [ loader, setLoader ] = useState(false);
    const [ AvatarImage, setAvatarImage ] = useState("");
    const [ AvatarImageId, setAvatarImageId ] = useState("");
    const [ UploadFileLoader, setUploadFileLoader ] = useState(false);

    // error message
    const [ errorEmail, setErrorEmail ] = useState("");
    const [ errorFname, setErrorFname ] = useState("");
    const [ errorLname, setErrorLname ] = useState("");
    const [ errorAddress, setErrorAddress ] = useState("");
    const [ errorPassword, setErrorPassword ] = useState("");
    const [ errorPhone, setErrorPhone ] = useState("");
    const [ errorPermission, setErrorPermission ] = useState("");

    // Regex
    const EmailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; // eslint-disable-line
    const PasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/; // eslint-disable-line

    // Textfield common onchange function
    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputValue((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    // show password
    const ShowHidePassword = () => {
        setshowpassword(!showpassword);
    };

    // upload product image 
    const UploadImage = async (e) => {
        setUploadFileLoader(true);
        // const files = e.target.files[0];
        // const formData = new FormData();
        // formData.append("file", files, files.name);
        // const fileresponse = await UploadFileAPI(accessToken, formData);
        // if(fileresponse) {
        //     setUploadFileLoader(false);
        //     setAvatarImage(fileresponse?.view_thumbnail_url);
        //     setAvatarImageId(fileresponse?.id);
        // };
    };

    // remove image uploaded
    const RemoveUploadImage = () => {
        setAvatarImage("");
        setAvatarImageId("");
    };

    // edit profile
    const AddUserFunct = async () => {
        if(inputValue?.Email !== "" || inputValue?.Password !== "" || inputValue?.Fname !== "" || inputValue?.Lname !== "" || inputValue?.Address !== "" || inputValue?.Address !== "" || PhoneNumber?.phone !== "" || permissionGet !== "") {
            if(EmailRegex.test(inputValue?.Email) === true || PasswordRegex.test(inputValue?.Password) === true || PhoneNumber?.phone?.length === 10) {
                setLoader(true);
                // const jsonData = JSON.stringify({ 
                //     user_id: GetCurrentUserSelected?.id,
                //     first_name: inputValue?.Fname,
                //     last_name: inputValue?.Lname,
                //     phone_code: PhoneNumber?.phone_code,
                //     phone: PhoneNumber?.phone,
                //     email: inputValue?.Email, 
                //     address: inputValue?.Address,
                //     password: inputValue?.Password,
                //     permissions: permissionGet,
                // });
                // const response = await UpdateUserAPI(jsonData, accessToken);
                // if(response?.status === 200){
                //     setTimeout(() => {
                //         toast.success(response.msg);
                //         Dispatch(editUserList(response?.data));
                //         ResetForm();
                //         setLoader(false);
                //         Navigate(USER_MANAGEMENT_URL);
                //     }, 100);
                // } else if(response?.status === 403){
                //     CommonLogout(accessToken, Dispatch);
                //     setLoader(false);
                // } else {
                //     toast.error(response.error);
                //     setLoader(false);
                // }
            } else {
                CheckValid(inputValue?.Email, {type: 'email', error: setErrorEmail });
                CheckValid(inputValue?.Password, {type: 'password', error: setErrorPassword });
                CheckValid(PhoneNumber?.phone, {type: 'phone', error: setErrorPhone });
            }
        } else {
            CheckValid(inputValue?.Fname, {type: 'Fname', error: setErrorFname });
            CheckValid(inputValue?.Lname, {type: 'Lname', error: setErrorLname });
            CheckValid(inputValue?.Address, {type: 'Address', error: setErrorAddress });
            CheckValid(inputValue?.Email, {type: 'email', error: setErrorEmail });
            CheckValid(inputValue?.Password, {type: 'password', error: setErrorPassword });
            CheckValid(PhoneNumber?.phone, {type: 'phone', error: setErrorPhone });
            CheckValid(permissionGet, {type: 'permission', error: setErrorPermission });
        };
    };

    return(<AuthLayout {...props} layoutClassName={"p-0"}>
            <section className="dashboard_wrapper">
                <div className="patient-layout-new">
                    <div className="header_back">
                        <Link to={App_url.Patient} className="header_backbtn"><i className="bi bi-arrow-left"></i> &nbsp; Back</Link>
                    </div>
                    <Scrollbars 
                        style={{ height: "calc(100vh - 168px)" }} 
                        className="ScrollbarsWrapper"
                        renderView={props => <div {...props} className="view"/>}
                    >
                        <div className="row" style={{ margin: "0 auto" }}>
                            <div className="col-md-4">
                                <div className="form-group fileUploadingform">
                                    <label>Upload Photo</label>
                                    {(AvatarImage !== "" && AvatarImage !== null) ? (<div className="uploadedImagewrps">
                                        {/* <Image src={`${API_URL}file/${AvatarImage}`} alt="product image" /> */}
                                        <AvatarGet type="product" url={AvatarImage} />
                                        <div className="closeIocn" onClick={() => RemoveUploadImage()}>
                                            <i className="bi bi-x-circle-fill"></i>
                                        </div>
                                    </div>)
                                    : (<><input type="file" className="form-control" onChange={(e) => UploadImage(e)} disabled={UploadFileLoader} accept=".png, .jpg, .jpeg" />
                                    {UploadFileLoader && <div className="filUploading">
                                        <Spinner animation="border" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </Spinner>
                                        Please wait, your file is uploading.
                                    </div>}
                                    </>)}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <InputField
                                    type="text"
                                    value={Fname}
                                    className={errorFname !== "" ? "form-control error-form" : "form-control"}
                                    placeholder="Enter First Name"
                                    label="First Name"
                                    name="Fname"
                                    onChange={handleChange}
                                    onKeyUp={(e) => CheckValid(e.target.value, {type: 'Fname', error: setErrorFname})}
                                    onKeyDown={EmptySpaceFieldValid}
                                    errormessage={errorFname}
                                />
                            </div>
                            <div className="col-md-4">
                                <InputField
                                    type="text"
                                    value={Lname}
                                    className={errorLname !== "" ? "form-control error-form" : "form-control"}
                                    placeholder="Enter Last Name"
                                    label="Last Name"
                                    name="Lname"
                                    onChange={handleChange}
                                    onKeyUp={(e) => CheckValid(e.target.value, {type: 'Lname', error: setErrorLname})}
                                    onKeyDown={EmptySpaceFieldValid}
                                    errormessage={errorLname}
                                />
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Gender</label>
                                    <div className="gendor_wrapper">
                                        <label>
                                            <input type="radio" name="gender" value="Male" />
                                            Male
                                        </label>
                                        <label>
                                            <input type="radio" name="gender" value="Female" />
                                            Female
                                        </label>
                                        <label>
                                            <input type="radio" name="gender" value="Other"/>
                                            Other
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <CountrySelectInput errorPhone={errorPhone} phone={PhoneNumber} setPhone={setPhoneNumber} CheckValid={CheckValid} setErrorPhone={setErrorPhone} />
                            </div>
                            <div className="col-md-4">
                                <InputField 
                                    type="text"
                                    value={Email}
                                    className={errorEmail !== "" ? "form-control error-form" : "form-control"}
                                    placeholder="Enter Patient Email"
                                    label="Patient Email"
                                    name="Email"
                                    onChange={handleChange}
                                    onKeyUp={(e) => CheckValid(e.target.value, {type: 'email', error: setErrorEmail})}
                                    onKeyDown={EmptySpaceFieldValid}
                                    errormessage={errorEmail}
                                />
                            </div>
                            <div className="col-md-4">
                                <div className="from-group">
                                    <label>Credit/ Debit card </label>
                                    <div className="formInput">
                                        <input type="text" className="form-control" placeholder="Enter Credit/Debit card"/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="from-group">
                                    <label>Referral Source </label>
                                    <div className="formInput">
                                        <input type="text" className="form-control" placeholder="Enter Referral Source"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row" style={{ margin: "0 auto" }}>
                            <h4 className="form_header">Emergency contact :</h4>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Name</label>
                                    <div className="formInput">
                                        <input type="text" className="form-control" placeholder="Enter Name"/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <InputField 
                                    type="text"
                                    value={Email}
                                    className={errorEmail !== "" ? "form-control error-form" : "form-control"}
                                    placeholder="Enter Email"
                                    label="Email address"
                                    name="Email"
                                    onChange={handleChange}
                                    onKeyUp={(e) => CheckValid(e.target.value, {type: 'email', error: setErrorEmail})}
                                    onKeyDown={EmptySpaceFieldValid}
                                    errormessage={errorEmail}
                                />
                            </div>
                            <div className="col-md-4">
                                <CountrySelectInput errorPhone={errorPhone} phone={PhoneNumber} setPhone={setPhoneNumber} CheckValid={CheckValid} setErrorPhone={setErrorPhone} />
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Relationship</label>
                                    <div className="formInput">
                                        <input type="text" className="form-control" placeholder="Enter Relationship"/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <InputField
                                    type="text"
                                    value={Address}
                                    className={errorAddress !== "" ? "form-control error-form" : "form-control"}
                                    placeholder="Enter Address"
                                    label="Address"
                                    name="Address"
                                    onChange={handleChange}
                                    onKeyUp={(e) => CheckValid(e.target.value, {type: 'Address', error: setErrorAddress})}
                                    onKeyDown={EmptySpaceFieldValid}
                                    errormessage={errorAddress}
                                />
                            </div>
                        </div>
                        <div className="row" style={{ margin: "0 auto" }}>
                            <h4 className="form_header">Insurance :</h4>
                            <div className="col-md-4">
                                <div className="from-group">
                                    <label>Name </label>
                                    <div className="formInput">
                                        <input type="text" className="form-control" placeholder="Enter Name"/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="from-group">
                                    <label>Id No </label>
                                    <div className="formInput">
                                        <input type="text" className="form-control" placeholder="Enter Id No"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row" style={{ margin: "0 auto" }}>
                            <h4 className="form_header">Patient Address :</h4>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Street Address</label>
                                    <div className="formInput">
                                        <input type="text" className="form-control" placeholder="Enter Street Address"/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Street Address</label>
                                    <div className="formInput">
                                        <input type="text" className="form-control" placeholder="Enter Street Address"/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>State</label>
                                    <div className="formInput">
                                        <input type="text" className="form-control" placeholder="Enter State"/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>City</label>
                                    <div className="formInput">
                                        <input type="text" className="form-control" placeholder="Enter City"/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Suite/Apt.</label>
                                    <div className="formInput">
                                        <input type="text" className="form-control" placeholder="Enter Suite/Apt"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row" style={{ margin: "0 auto" }}>
                            <div className="col-md-12">
                                <div className="form-group-btn" style={{ textAlign: "right" }}>
                                    <button type="button" className="btn addbtncmn" onClick={() => AddUserFunct()}>
                                        {loader && (<BtnLoader />)}
                                        {Location === "edit-user" ? "Save" : "Create"}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Scrollbars>
                </div>
            </section>
        </AuthLayout>
    )
}

export default AddEditPatient;