export const API = {
    Home:"/"
}

export const App_url = {
    Dashboard:"/",
    Login:"/login",
    ForgotPassword:"/forgot-password",
    ResetPassword: "/reset-password",
    Patient:"/patient",
    PatientDetails:"/patient/summary",
    AddPatient:"/patient/add",
    EditPatient:"/patient/edit",
    Admission:"/admission",
    Appointment:"/appointment",
    TreatmentPlan:"/treatment-plan",
    Notes:"/notes",
    MAR:"/mar",
    VitalSigns:"/vital-signs",
}