import React from "react";
import DefaultPorduct from "../../assets/img/DefaultProduct.jpg";
import DummyAvatar from "../../assets/img/dummyimage.png";
import {App_url} from "./../../components/Shared/constant";

const AvatarGet = (props) => {
    const { type, url } = props;

    return(<div className="avatrshow">
        {type === "product" ? ((url === null || url === undefined) ? <img src={DefaultPorduct} alt="product" /> : <img src={App_url.Dashboard+ "file/" + url} alt="product"/>) :
        ((url === null || url === undefined) ? <img src={DummyAvatar} alt="avatar" /> : <img src={DefaultPorduct} alt="avatar" />)}
    </div>)
}

export default AvatarGet;