import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Dropdown, Image } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import AvatarGet from "../Common/AvatarGet";
import { useSelector, useDispatch } from "react-redux";
import DummyUserImage from "../../assets/img/dummyimage.png";
import ToolTip from "../Common/ToolTip";
import { App_url } from "./constant";

const Header = () => {
    const Location = useLocation().pathname?.split("/")[1].replaceAll("-", " ");
    const Navigate = useNavigate();
    const Dispatch = useDispatch();

    // logout function
    const LogoutFnct = () => {
    };
    const HeaderList = [
        {
            title:"Messages",
            icon:"bi bi-chat-dots",
            to:App_url.Dashboard,
        },
        {
            title:"Notifications",
            icon:"bi bi-bell",
            to:App_url.Dashboard,
        },
        {
            title:"Settings",
            icon:"bi bi-gear",
            to:App_url.Dashboard,
        },

    ]
    return(
        <div className="head_section">
            <h4>Hospital Management</h4>
            <div className="dropdownwraps">
                {HeaderList?.map((item, index)=>(
                    <ToolTip title={item?.title} key={index} >
                        <button type="button" className="icon_button me-2" >
                            <i className={item?.icon}></i>
                            {/* {addToCartLists?.length > 0 && (<Badge bg="secondary">{addToCartLists?.length}</Badge>)} */}
                        </button>
                    </ToolTip>
                ))}
            </div>
            <div className="userdropdown">
                <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic">
                        <Image src={DummyUserImage} className="rounded" alt="user"/>
                        Admin User
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item >Logout</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </div>
    )
}

export default Header;