/*eslint-disable */
import React, { useState, useEffect } from "react";
import Countries from "./CountryCodes.json";
import CaretDown from "../../../assets/img/caret-down.svg";
import Dropdown from 'react-bootstrap/Dropdown';

const CountryMobileInput = (props) => {
    const { phone, setPhone, CheckValid, setErrorPhone, errorPhone } = props;
    const [ countryselect, setCountryselect] = useState("");
    const [ SelectFlag, setSelectFlag ] = useState("US");
    const FlagsUrl = "https://purecatamphetamine.github.io/country-flag-icons/3x2/";

    useEffect(() => {
        Countries.filter((elm) => {
            if(elm?.phone_code === parseInt(phone?.phone_code)) {
                return setSelectFlag(elm?.country_code);
            }
        })
    }, [ phone ]);

    // select country code & image
    const SelectCountryCode = (flags) => {
        setSelectFlag(flags.country_code);
        setPhone({...phone, phone_code: flags.phone_code});
    };

    // onChange effect 
    const OnchangeNoGet = (e) => {
        if(e.target.value.length <= 10) {
            setPhone({...phone, phone: e.target.value});
        };
    };

    return (<div className="form-group">
    <label>Mobile No</label>
    <div className={errorPhone ? "selectNoInputField error-form" : "selectNoInputField"}>
        <Dropdown className="inputBoxSelect">
            <Dropdown.Toggle className="selectEmojis" variant="success" id="dropdown-basic">
                {SelectFlag !== "" && <img src={FlagsUrl + SelectFlag + ".svg"} className="flagsimg" alt="call image" />}
                <div className="countselet">+{phone?.phone_code}</div>
                <img src={CaretDown} className="arrowdownimg" alt="caret down" />
            </Dropdown.Toggle>
            <Dropdown.Menu className="flagsDropdown">
                <div className="flagsDropdownMain">
                    {Countries.filter((elm) => {
                        if(countryselect === "") {
                            return elm;
                        } else if(elm?.country_en.toLowerCase()?.includes(countryselect && countryselect?.toLowerCase())) {
                            return elm;
                        } 
                    }).map((flags, index) => {
                        return(<Dropdown.Item key={index} onClick={() => SelectCountryCode(flags)}>
                            <img src={FlagsUrl + flags.country_code + ".svg"} />
                            <span>{flags?.country_en} (+{flags.phone_code})</span>
                    </Dropdown.Item>)
                    })}
                </div>
                <input type="text" value={countryselect} onChange={(e) => setCountryselect(e.target.value)} placeholder="Search Country..." />
            </Dropdown.Menu>
        </Dropdown>
        <input 
            type="number" 
            className="inputBoxs" 
            value={phone?.phone}
            placeholder="Enter Mobile No"
            onChange={(e) => OnchangeNoGet(e)}
            onKeyUp={(e) => CheckValid(e.target.value, {type: 'phone', error: setErrorPhone })}
        />
    </div>
    {errorPhone !== "" && (<div className="errormendatorr"><i className="bi bi-x-circle-fill"></i>{errorPhone}</div>)}
</div>)
}

export default CountryMobileInput;