import React, { useState, useContext, useEffect } from "react";
import { App_url } from "../Shared/constant";
import BackButton from "../Common/BackButton";
import InputField from "../Common/InputField";
import CheckValid from "../Common/CheckValid";
import EmptySpaceFieldValid from "../Common/EmptySpaceFieldValid";
import { OverlayTrigger, Tooltip, Dropdown } from 'react-bootstrap';
import CountrySelectInput from "../Common/CountrySelectInput/CountryMobileInput";
import { Scrollbars } from 'react-custom-scrollbars-2';
import wsSend_request from "../../Api/ws/ws_request";
import { WebSocketContext } from "../../App";
import Countries from "../Common/CountrySelectInput/CountryCodes.json";
import CaretDown from "../../assets/img/caret-down.svg";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const AddEditSupplier = (props) => {
    const { AddSupplierForPorduct, handleClose } = props;
    const { device_id } = useSelector((state) => state.adminReducers);
    const { websocket } = useContext(WebSocketContext);
    const Navigate = useNavigate();
    const [ inputValue, setInputValue ] = useState({ Fname : "", Lname: "", CompanyName: "", Email: "", Address: "", VATRegistrationNo: "", RegistrationNo: "" });
    const { Email, Fname, Lname, Address, CompanyName, VATRegistrationNo, RegistrationNo } = inputValue;
    const [ PhoneNumber, setPhoneNumber ] = useState({
        phone_code: "1",
        phone: ""
    });
    const [ CountrySelect, setCountrySelect ] = useState("");
    const FlagsUrl = "https://purecatamphetamine.github.io/country-flag-icons/3x2/";
 
    // Regex
    const EmailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; // eslint-disable-line

    // error message
    const [ errorEmail, setErrorEmail ] = useState("");
    const [ errorFname, setErrorFname ] = useState("");
    const [ errorLname, setErrorLname ] = useState("");
    const [ errorAddress, setErrorAddress ] = useState("");
    const [ errorPhone, setErrorPhone ] = useState("");
    const [ errorCompanyName, setErrorCompanyName ] = useState("");
    const [ errorVATRegistrationNo, setErrorVATRegistrationNo ] = useState("");
    const [ errorRegistrationNo, setErrorRegistrationNo ] = useState("");

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputValue((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    // add more email field
    const [ AddMoreEmailState, setAddMoreEmailState ] = useState([]);
    const AddMoreEmail = () => {
        const EmailAddState = [...AddMoreEmailState];
        var ArrayEmailObj = { email: "", id: (Math.random() + 1).toString(36).substring(7) };
        EmailAddState.push(ArrayEmailObj);
        setAddMoreEmailState(EmailAddState);
    };
    const handleOnChangeEmail = (data_, index) => {
        const UpdateEmailArray = AddMoreEmailState.map((elm) => {
            if(elm?.id === index) {
                return{
                    ...elm,
                    email: data_.target.value
                }
            } else 
            return elm;
        })
        setAddMoreEmailState(UpdateEmailArray);
    };
    const DeleteEmail = (value) => {
        const DeleteEmailArray = [];
        AddMoreEmailState && AddMoreEmailState.filter((elm) => {
            if(elm?.id !== value?.id) {
                return DeleteEmailArray.push(elm);
            }
        })
        setAddMoreEmailState(DeleteEmailArray);
    };

    // add phone number filed
    const [ AddMorePhoneState, setAddMorePhoneState ] = useState([]);
    const AddPhoneEmail = () => {
        const PhoneAddState = [...AddMorePhoneState];
        var ArrayEmailObj = { phone: "", phone_code: "1", phone_flag: "US" ,id: (Math.random() + 1).toString(36).substring(7) };
        PhoneAddState.push(ArrayEmailObj);
        setAddMorePhoneState(PhoneAddState);
    };
    const handleOnChangePhone = (data_, index) => {
        if(data_.target.value.length <= 10) {
            const UpdatePhoneArray = AddMorePhoneState.map((elm) => {
                if(elm?.id === index) {
                    return{
                        ...elm,
                        phone: data_.target.value,
                    }
                } else 
                return elm;
            })
            setAddMorePhoneState(UpdatePhoneArray);
        }
    };

    const SelectCountryCode = (flag, index) => {
        const UpdatePhoneArray = AddMorePhoneState.map((elm) => {
            if(elm?.id === index) {
                return{
                    ...elm,
                    phone_flag:  flag?.country_code,
                    phone_code: flag?.phone_code,
                }
            } else 
            return elm;
        })
        setAddMorePhoneState(UpdatePhoneArray);
    };

    const DeletePhone = (value) => {
        const DeletePhoneArray = [];
        AddMorePhoneState && AddMorePhoneState.filter((elm) => {
            if(elm?.id !== value?.id) {
                return DeletePhoneArray.push(elm);
            }
        })
        setAddMorePhoneState(DeletePhoneArray);
    };


    const [ emailPhoneArray, setEmailPhoneArray ] = useState({});

    useEffect(() => {
       if(AddMoreEmailState.length > 0 ||  AddMorePhoneState.length > 0) {
            const EmailArray = [];
            const PhoneCodeAray = [];
            let EmailMobileObject = {
                "emails": "",
                "phones": ""
            };

            AddMoreEmailState?.filter((elm) => {
                if(EmailRegex.test(elm?.email) === true) {
                    EmailArray.push(elm?.email);
                    EmailMobileObject.emails = EmailArray.toString();
                };
            });
            AddMorePhoneState?.filter((elm) => {
                if(elm?.phone?.length === 10) {
                    const PhoneObj = {
                        phone: elm?.phone,
                        phone_code: elm?.phone_code,
                    }
                    PhoneCodeAray.push(PhoneObj);
                    EmailMobileObject.phones = PhoneCodeAray;
                };
            });
            setEmailPhoneArray(EmailMobileObject);
       };
    }, [ AddMoreEmailState, AddMorePhoneState ]);

    // create supplier
    const CreateSupplierFnct = () => {
        if(Email !== "" && Fname !== "" && Lname !== "" && Address !== "" && CompanyName !== "" && VATRegistrationNo !== "" && RegistrationNo !== "" && PhoneNumber?.phone !== "") {
            if(EmailRegex.test(inputValue?.Email) === true && PhoneNumber?.phone?.length === 10) {
                if(AddMoreEmailState.length > 0 ||  AddMorePhoneState.length > 0) {
                    let param = { 
                        "transmit": "broadcast", 
                        "url": "add_supplier",
                        "request" : { 
                            "name": Fname + " " + Lname, 
                            "email": Email, 
                            "phone_code": PhoneNumber?.phone_code, 
                            "phone": PhoneNumber?.phone, 
                            "company" : CompanyName, 
                            "address" : Address, 
                            "vat_registration_no" : VATRegistrationNo, 
                            "registration_no" : RegistrationNo, 
                            "has_contact": true,
                            "contact": emailPhoneArray,
                            "DeviceId" : device_id
                        }
                    };
                    wsSend_request(websocket, param);
                    setTimeout(() => {
                        if(AddSupplierForPorduct === undefined) {
                            Navigate(App_url?.Dashboard);
                        } else {
                            handleClose()
                        };
                        ClearForm()
                    }, 200);
                } else {
                    let param = { 
                        "transmit": "broadcast", 
                        "url": "add_supplier",
                        "request" : { 
                            "name": Fname + " " + Lname, 
                            "email": Email, 
                            "phone_code": PhoneNumber?.phone_code, 
                            "phone": PhoneNumber?.phone, 
                            "company" : CompanyName, 
                            "address" : Address, 
                            "vat_registration_no" : VATRegistrationNo, 
                            "registration_no" : RegistrationNo, 
                            "has_contact": false,
                            "DeviceId" : device_id
                        }
                    };
                    wsSend_request(websocket, param);
                    setTimeout(() => {
                        if(AddSupplierForPorduct === undefined) {
                            Navigate(App_url?.Dashboard);
                        } else {
                            handleClose()
                        };
                        ClearForm()
                    }, 200);
                };
            } else {
                CheckValid(inputValue?.Email, {type: 'email', error: setErrorEmail });
                CheckValid(PhoneNumber?.phone, {type: 'phone', error: setErrorPhone });
            }
        } else {
            CheckValid(Fname, {type: 'Fname', error: setErrorFname});
            CheckValid(Lname, {type: 'Lname', error: setErrorLname});
            CheckValid(CompanyName, {type: 'CompanyName', error: setErrorCompanyName});
            CheckValid(inputValue?.Email, {type: 'email', error: setErrorEmail });
            CheckValid(PhoneNumber?.phone, {type: 'phone', error: setErrorPhone });
            CheckValid(Address, {type: 'Address', error: setErrorAddress});
            CheckValid(VATRegistrationNo, {type: 'VATRegistrationNo', error: setErrorVATRegistrationNo});
            CheckValid(RegistrationNo, {type: 'RegistrationNo', error: setErrorRegistrationNo});
        };
    };

    // clear form
    const ClearForm = () => {
        setInputValue({...inputValue, Fname : "", Lname: "", CompanyName: "", Email: "", Address: "", VATRegistrationNo: "", RegistrationNo: "" });
        setErrorFname("");
        setErrorLname("");
        setErrorCompanyName("");
        setErrorEmail("");
        setErrorPhone("");
        setErrorAddress("");
        setErrorVATRegistrationNo("");
        setErrorRegistrationNo("");
        setAddMorePhoneState([]);
        setAddMorePhoneState([]);
        setPhoneNumber({...PhoneNumber, phone_code: "1", phone: ""});
    };

    return(<React.Fragment>
        <BackButton target_url={App_url?.Dashboard} title={"Back"} />
        <section className="dashboard_wrapper_form" >
            <Scrollbars 
                style={{ height: "calc(100vh - 150px)"}} 
                className="ScrollbarsWrapper"
                renderView={props => <div {...props} className="view"/>}
            >
                <div className="scrollform">
                    <div className="row" style={{ margin: "0 auto" }}>
                        <div className="col-md-4">
                            <InputField
                                type="text"
                                value={Fname?.trim()}
                                className={errorFname !== "" ? "form-control error-form" : "form-control"}
                                placeholder="Enter First Name"
                                label="First Name"
                                name="Fname"
                                onChange={handleChange}
                                onKeyUp={(e) => CheckValid(e.target.value, {type: 'Fname', error: setErrorFname})}
                                onKeyDown={EmptySpaceFieldValid}
                                errormessage={errorFname}
                            />
                        </div>
                        <div className="col-md-4">
                            <InputField
                                type="text"
                                value={Lname.trim()}
                                className={errorLname !== "" ? "form-control error-form" : "form-control"}
                                placeholder="Enter Last Name"
                                label="Last Name"
                                name="Lname"
                                onChange={handleChange}
                                onKeyUp={(e) => CheckValid(e.target.value, {type: 'Lname', error: setErrorLname})}
                                onKeyDown={EmptySpaceFieldValid}
                                errormessage={errorLname}
                            />
                        </div>
                        <div className="col-md-4">
                            <InputField
                                type="text"
                                value={CompanyName}
                                className={errorCompanyName !== "" ? "form-control error-form" : "form-control"}
                                placeholder="Enter Company Name"
                                label="Company Name"
                                name="CompanyName"
                                onChange={handleChange}
                                onKeyUp={(e) => CheckValid(e.target.value, {type: 'CompanyName', error: setErrorCompanyName})}
                                onKeyDown={EmptySpaceFieldValid}
                                errormessage={errorCompanyName}
                            />
                        </div>
                        <div className="col-md-4">
                            <div className="formInput formplusbtonset">
                                <div className={AddMoreEmailState?.length > 0 ? "filedaddwraps phoneListShow" : "filedaddwraps"}>
                                    <InputField 
                                        type="text"
                                        value={Email}
                                        className={errorEmail !== "" ? "form-control error-form" : "form-control"}
                                        placeholder="Enter Email"
                                        label="Email address"
                                        name="Email"
                                        onChange={handleChange}
                                        onKeyUp={(e) => CheckValid(e.target.value, {type: 'email', error: setErrorEmail})}
                                        onKeyDown={EmptySpaceFieldValid}
                                        errormessage={errorEmail}
                                    />
                                    <OverlayTrigger
                                        placement="bottom"
                                        overlay={<Tooltip id="button-tooltip-2" style={{ position: "fixed" }}>Add Email address</Tooltip>}
                                    >
                                        <button type="button" className="btn addbtn" onClick={() => AddMoreEmail()}>
                                            <i className="bi bi-plus"></i>
                                        </button>
                                    </OverlayTrigger>
                                </div>
                                <div className={AddMoreEmailState.length > 0 ? "EmailMarginWrapper active" : "EmailMarginWrapper"}>
                                    {AddMoreEmailState && AddMoreEmailState.map((fields, index) => {
                                        return(<div className="form-group" key={index}>
                                            <div className="filedaddwraps">
                                            <input
                                                onChange={(e) => handleOnChangeEmail(e, fields?.id)}
                                                value={fields?.email}
                                                className="form-control"
                                                type="text"
                                                placeholder="Enter Email" 
                                            />
                                            <OverlayTrigger
                                                placement="bottom"
                                                overlay={<Tooltip id="button-tooltip-2" style={{ position: "fixed" }}>Remove Email</Tooltip>}
                                            >
                                                <button type="button" className="btn btn-clsoe" onClick={() => DeleteEmail(fields)}>
                                                    <i className="bi bi-x-circle"></i>
                                                </button>
                                            </OverlayTrigger>
                                        </div>
                                        {(fields?.email.length > 1 && EmailRegex.test(fields?.email) === false) && (<div className="errormendatorr"><i className="bi bi-x-circle-fill"></i>Please enter valid email address</div>)}
                                    </div>)})}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            {/* <div className="form-group"> */}
                                <div className="formInput formplusbtonset">
                                    <div className={AddMorePhoneState?.length > 0 ? "filedaddwraps phoneListShow" : "filedaddwraps"}>
                                        <CountrySelectInput phone={PhoneNumber} setPhone={setPhoneNumber} CheckValid={CheckValid} errorPhone={errorPhone} setErrorPhone={setErrorPhone} />
                                        <OverlayTrigger
                                            placement="bottom"
                                            overlay={<Tooltip id="button-tooltip-2" style={{ position: "fixed" }}>Add Mobile No</Tooltip>}
                                        >
                                            <button type="button" className="btn addbtn" onClick={() => AddPhoneEmail()}>
                                                <i className="bi bi-plus"></i>
                                            </button>
                                        </OverlayTrigger>
                                    </div>
                                    <div className={AddMorePhoneState.length > 0 ? "EmailMarginWrapper active" : "EmailMarginWrapper"}>
                                        {AddMorePhoneState && AddMorePhoneState.map((fields, index) => {
                                            return(<div className="form-group" key={index}>
                                            <div className="filedaddwraps" >
                                                <div className="selectNoInputField">
                                                    <Dropdown className="inputBoxSelect">
                                                        <Dropdown.Toggle className="selectEmojis" variant="success" id="dropdown-basic">
                                                            {fields?.phone_flag !== "" && <img src={FlagsUrl + fields?.phone_flag + ".svg"} className="flagsimg" alt="call image" />}
                                                            <div className="countselet">+{fields?.phone_code}</div>
                                                            <img src={CaretDown} className="arrowdownimg" alt="caret down" />
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu className="flagsDropdown">
                                                            <div className="flagsDropdownMain">
                                                                {Countries.filter((elm) => {
                                                                    if(CountrySelect === "") {
                                                                        return elm;
                                                                    } else if(elm?.country_en.toLowerCase()?.includes(CountrySelect && CountrySelect?.toLowerCase())) {
                                                                        return elm;
                                                                    } 
                                                                }).map((flags, index) => {
                                                                    return(<Dropdown.Item key={index} onClick={() => SelectCountryCode(flags, fields?.id)}>
                                                                        <img src={FlagsUrl + flags.country_code + ".svg"} />
                                                                        <span>{flags?.country_en} (+{flags.phone_code})</span>
                                                                </Dropdown.Item>)
                                                                })}
                                                            </div>
                                                            <input type="text" value={CountrySelect} onChange={(e) => setCountrySelect(e.target.value)} placeholder="Search Country..." />
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                    <input
                                                        value={fields?.phone}
                                                        onChange={(e) => handleOnChangePhone(e, fields?.id)}
                                                        className="inputBoxs"
                                                        type="number" 
                                                        placeholder="Enter Phone Number" 
                                                    />
                                                </div>
                                                <OverlayTrigger
                                                    placement="bottom"
                                                    overlay={<Tooltip id="button-tooltip-2" style={{ position: "fixed" }}>Remove Email</Tooltip>}
                                                >
                                                    <button type="button" className="btn btn-clsoe" onClick={() => DeletePhone(fields)}>
                                                        <i className="bi bi-x-circle"></i>
                                                    </button>
                                                </OverlayTrigger>
                                            </div>
                                            {(fields?.phone.length > 1 && fields?.phone.length !== 10) && (<div className="errormendatorr"><i className="bi bi-x-circle-fill"></i>Please enter 10 digit mobile no</div>)}
                                        </div>)})}
                                    </div>
                                </div>
                            {/* </div> */}
                            
                        </div>
                        <div className="col-md-4">
                            <InputField 
                                type="text"
                                value={Address}
                                className={errorAddress !== "" ? "form-control error-form" : "form-control"}
                                placeholder="Enter Address"
                                label="Address"
                                name="Address"
                                onChange={handleChange}
                                onKeyUp={(e) => CheckValid(e.target.value, {type: 'Address', error: setErrorAddress})}
                                onKeyDown={EmptySpaceFieldValid}
                                errormessage={errorAddress}
                            />
                        </div>
                        <div className="col-md-4">
                            <InputField 
                                type="text"
                                value={VATRegistrationNo}
                                className={errorVATRegistrationNo !== "" ? "form-control error-form" : "form-control"}
                                placeholder="Enter VAT Registration No."
                                label="VAT Registration No."
                                name="VATRegistrationNo"
                                onChange={handleChange}
                                onKeyUp={(e) => CheckValid(e.target.value, {type: 'VATRegistrationNo', error: setErrorVATRegistrationNo})}
                                onKeyDown={EmptySpaceFieldValid}
                                errormessage={errorVATRegistrationNo}
                            />
                        </div>
                        <div className="col-md-4">
                            <InputField 
                                type="text"
                                value={RegistrationNo}
                                className={errorRegistrationNo !== "" ? "form-control error-form" : "form-control"}
                                placeholder="Enter Registration No."
                                label="Registration No."
                                name="RegistrationNo"
                                onChange={handleChange}
                                onKeyUp={(e) => CheckValid(e.target.value, {type: 'RegistrationNo', error: setErrorRegistrationNo})}
                                onKeyDown={EmptySpaceFieldValid}
                                errormessage={errorRegistrationNo}
                            />
                        </div>
                        <div className="col-md-12">
                            <div className="form-group-btn" onClick={() => CreateSupplierFnct()} style={{ textAlign: "right" }}>
                                <button className="btn addbtncmn">Add</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Scrollbars>
        </section>
    </React.Fragment>)
}

export default AddEditSupplier;