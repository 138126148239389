import React from "react";
import PropTypes from "prop-types";
import {
  FloatingLabel,
  Form,
  FormSelect,
  InputGroup as ReactInputGroup,
} from "react-bootstrap";
import { useMemo } from "react";
import { uuidv4 } from "./utils";
import EmptySpaceFieldValid from "./EmptySpaceFieldValid";

const removeWhitespace = (value) => {
  let formattedText = value;
  formattedText = formattedText.replace(/\s/g, "");
  return formattedText;
};

const removeMultipleBlankSpace = (string, substring) => {
  // string = string.replace(/\s+/g, " ").trim();
  string = string.replace(/\s+/g, " ");
  const isCorrect = string.length > 0;

  const startsWithSubstring = string.startsWith(substring);
  const endsWithSubstring = string.endsWith(substring);

  //   return { isCorrect, result: string, startsWithSubstring, endsWithSubstring };
  return string;
};

export const FormGroupControl = (props) => {
  if (props?.labelFloat) {
    return (
      <FloatingLabel
        label={props?.label}
        labelFloat={props?.labelFloat}
        className={props?.className}
        controlId={props?.controlId}
      >
        {props?.children}
      </FloatingLabel>
    );
  }
  return (
    <Form.Group className={props?.className} controlId={props?.controlId}>
      {props?.children}
    </Form.Group>
  );
};
export default function InputGroup(props) {
  const {
    className,
    label,
    onChange,
    error,
    name,
    type,
    id,
    formClassName,
    labelClassName,
    errorClassName,
    placeholder,
  } = props;
  let ids = useMemo(() => uuidv4(), []); // removed name dependency from here
  // let FormGroupControl = Form.Group
  const data = {};
  if (props?.as) {
    data.as = props?.as;
  }
  const handleEmailChange = (value) => {
    let email = value;

    // Remove spaces and special characters
    email = email.replace(/\s/g, "");
    email = email.replace(/[^a-zA-Z0-9@.-]/g, "");

    // Convert to lowercase
    email = email.toLowerCase();

    // Restrict to one '@' character
    const atIndex = email.indexOf("@");
    if (atIndex !== -1) {
      const beforeAt = email.slice(0, atIndex + 1);
      const afterAt = email.slice(atIndex + 1).replace(/@/g, "");
      email = beforeAt + afterAt;
    }

    return email;
  };

  function changeHandler(e) {
    const { name, checked, type } = e.target;
      if(props?.inputType !== "select"){
        const substring = "";
        if(e.target.value != " "){
          e.target.value = removeMultipleBlankSpace(e.target.value, substring);
        }else{
          e.target.value = "";
        }
      }
      onChange(e);
  }
  const InputForm = () => {
    if (props?.inputType === "select") {
      return (
        <FormSelect
          multiple={props?.multiple}
          name={name}
          id={id}
          className={className}
          value={props?.value}
          isInvalid={error ? true : false}
          onChange={onChange}
        >
          {props?.option?.length === 0 ? (
            <option disabled={true} value={""}>
              No data found
            </option>
          ) : (
            props?.option?.map((item, index) => (
              <option
                key={index?.toString()}
                disabled={item?.disabled}
                value={item?.value}
              >
                {item?.title}
              </option>
            ))
          )}
        </FormSelect>
      );
    }
    const FormControl = () => {
      return (
        <Form.Control
          id={id}
          type={type}
          onWheel={(e) => e.target.blur()}
          placeholder={placeholder}
          name={name}
          onChange={changeHandler}
          className={className}
          {...data}
          onKeyDown={(e) => EmptySpaceFieldValid(e)}
          value={props?.value}
          isInvalid={error ? true : false}
        />
      );
    };
    if (props?.leftLabel || props?.rightLabel) {
      return (
        <ReactInputGroup>
          {props?.leftLabel && (
            <ReactInputGroup.Text
              id={`left_icon_${id}`}
              className={` ${props?.leftIconClick ? "cursor-pointer" : ""}`}
              onClick={props?.leftIconClick}
            >
              {props?.leftLabel}
            </ReactInputGroup.Text>
          )}
          {FormControl()}
          {props?.rightLabel && (
            <ReactInputGroup.Text
              id={`left_icon_${id}`}
              className={` ${props?.rightIconClick ? "cursor-pointer" : ""}`}
              onClick={props?.rightIconClick}
            >
              {props?.rightLabel}
            </ReactInputGroup.Text>
          )}
        </ReactInputGroup>
      );
    }
    return FormControl();
  };
  const LabelForm = () => {
    return label && !props?.labelFloat ? (
      <React.Fragment>
        <Form.Label className={`${labelClassName}`}>
          {label} {props?.required && <span style={{ color: "red" }}>*</span>}
        </Form.Label>
      </React.Fragment>
    ) : (
      <React.Fragment></React.Fragment>
    );
  };
  const ErrorForm = () => {
    return error ? (
      <React.Fragment>
        <Form.Control.Feedback type="invalid" className={`${errorClassName}`}>
          {error}
        </Form.Control.Feedback>
      </React.Fragment>
    ) : (
      <React.Fragment></React.Fragment>
    );
  };
  // if(props?.inputType === "select"){
  //     return(
  //         <FormGroupControl label={label} labelFloat={props?.labelFloat}  className={`form_group mt-0 form_style_${props?.formStyle} form-size-${props?.size} radius-${props?.radius} ${formClassName} ${error?"form-validate":""} form_variate`} controlId={ids}>
  //             <LabelForm/>
  //                 <InputForm/>
  //             <ErrorForm/>
  //         </FormGroupControl>
  //     )
  // }
  return (
    <FormGroupControl
      label={label}
      labelFloat={props?.labelFloat}
      className={`form_group ${
        props?.leftIcon ||
        props?.rightIcon ||
        props?.rightLabel ||
        props?.leftLabel
          ? "input_group_icon"
          : ""
      } mt-0 form_style_${props?.formStyle} form-size-${props?.size} ${props?.inline?"d-inline-block":""} radius-${
        props?.radius
      } ${formClassName} ${error ? "form-validate" : ""} form_variate`}
      controlId={ids}
    >
      {LabelForm()}
      {InputForm()}
      {ErrorForm()}
    </FormGroupControl>
  );
}
InputGroup.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.any,
  onChange: PropTypes.func,
  error: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  id: PropTypes.string,
  formClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  errorClassName: PropTypes.string,
  as: PropTypes.any,
  radius: PropTypes.number,
  size: PropTypes.number,
  labelFloat: PropTypes?.bool,
  value: PropTypes?.any,
  formStyle: PropTypes.any,
  required: PropTypes?.bool,
  inputType: PropTypes?.string,
  option: PropTypes?.array,
  leftIcon: PropTypes?.any,
  rightIcon: PropTypes?.any,
  leftLabel: PropTypes?.any,
  rightLabel: PropTypes?.any,
  onKeyDown: PropTypes?.func,
  // onClickLeftLabel:PropTypes?.func,
  // onClickRightLabel:PropTypes?.func,
  leftIconClass: PropTypes?.any,
  rightIconClass: PropTypes?.any,
  onEditorChange: PropTypes.func,
  rightIconClick: PropTypes.func,
  leftIconClick: PropTypes.func,
  multiple: PropTypes?.bool,
  inline: PropTypes?.bool
};
InputGroup.defaultProps = {
  className: "",
  label: "",
  placeholder: "",
  onChange: () => {},
  onEditorChange: () => {},
  error: "",
  name: "name",
  type: "text",
  id: "",
  formClassName: "",
  labelClassName: "",
  errorClassName: "",
  as: "",
  radius: 1,
  size: 2,
  labelFloat: false,
  value: "",
  formStyle: "normal",
  required: false,
  inputType: "input",
  option: [],
  leftIcon: "",
  rightIcon: "",
  leftIconClass: "",
  rightIconClass: "",
  leftLabel: "",
  rightLabel: "",
  onKeyDown: ()=>{},
  // onClickLeftLabel:()=>{},
  // onClickRightLabel:()=>{},
  multiple: false,
  inline:false
};
