// empty space validation fucntion
const CheckValid = (value, states) => {
    const EmailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; // eslint-disable-line
    const PasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/; // eslint-disable-line

    // email validation
    if(states?.type === "email") {
        if(value !== "") {
            if(EmailRegex.test(value) === true) {
                return states?.error("");    
            } else {
                if(EmailRegex.test(value) === false) {
                    return states?.error("Please enter valid email address");
                } else {
                    return states?.error("");
                }
            }
        } else {
            if(value === "") {
                return states?.error("Email address cannot be empty");
            } else if(EmailRegex.test(value) === false) {
                return states?.error("Please enter valid email address");
            } else {
                return states?.error("");
            }
        };
    };

    // password validation
    if(states?.type === "password"){
        if(value !== "") {
            if(PasswordRegex.test(value) === true) {
                return states?.error("");
            } else {
                if(PasswordRegex.test(value) === false) {
                    return states?.error("Please Enter Valid Password");
                } else {
                    return states?.error("");
                }
            }
        } else {
            if (value === "") {
                return states?.error("Password cannot be empty");
            } else if(PasswordRegex.test(value) === false) {
                return states?.error("Please Enter Valid Password");
            } else {
                return states?.error("");
            }
        }
    }

    // old password validation
    if(states?.type === "oldpassword"){
        if(value !== "") {
            if(PasswordRegex.test(value) === true) {
                return states?.error("");
            } else {
                if(PasswordRegex.test(value) === false) {
                    return states?.error("Please Enter Valid Password");
                } else {
                    return states?.error("");
                }
            }
        } else {
            if (value === "") {
                return states?.error("Old Password cannot be empty");
            } else if(PasswordRegex.test(value) === false) {
                return states?.error("Please Enter Valid Password");
            } else {
                return states?.error("");
            }
        }
    }

    // new password validation
    if(states?.type === "newpassword"){
        if(value !== "") {
            if(PasswordRegex.test(value) === true) {
                return states?.error("");
            } else {
                if(PasswordRegex.test(value) === false) {
                    return states?.error("Please Enter Valid Password");
                } else {
                    return states?.error("");
                }
            }
        } else {
            if (value === "") {
                return states?.error("New Password cannot be empty");
            } else if(PasswordRegex.test(value) === false) {
                return states?.error("Please Enter Valid Password");
            } else {
                return states?.error("");
            }
        }
    }

    // first name validation
    if(states?.type === "Fname"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("First name cannot be empty");
        }
    }

    // last name validation
    if(states?.type === "Lname"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Last name cannot be empty");
        }
    }

    // Business name validation
    if(states?.type === "Business_name"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Business name cannot be empty");
        }
    }

    // Address validation
    if(states?.type === "Address"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Address cannot be empty");
        }
    }

    // phone validation
    if(states?.type === "phone"){
        if(value !== "") {
            if(value.length === "10") {
                return states?.error("");
            } else {
                if(value.length < 10) {
                    return states?.error("Please enter 10 digit mobile no");
                } else {
                    return states?.error("");
                }
            }
        } else {
            if (value === "") {
                return states?.error("Mobile no cannot be empty");
            } else if(value.length < 10) {
                return states?.error("Please enter 10 digit mobile no");
            } else {
                return states?.error("");
            }
        }
    }

    // Product Name validation
    if(states?.type === "ProductName"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Product name cannot be empty");
        }
    }

    // Product Name validation
    if(states?.type === "Model"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Model cannot be empty");
        }
    }

    // Brand validation
    if(states?.type === "Brand"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Brand cannot be empty");
        }
    }

    // Select brand validation
    if(states?.type === "Select Brand"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Please select brand");
        }
    }

    // Unit const price validation
    if(states?.type === "Unitconstprice"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Unit cost price cannot be empty");
        }
    }

    // Unit const price validation
    if(states?.type === "Description"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Description cannot be empty");
        }
    }

    // Supplier name validation
    if(states?.type === "SupplierName"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Supplier name cannot be empty");
        }
    }

    // permission validation
    if(states?.type === "permission"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Please select permisssion");
        }
    }

    // brand name validation
    if(states?.type === "Brand Name"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Brand name cannot be empty");
        }
    }

    // model name validation
    if(states?.type === "Model Name"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Model name cannot be empty");
        }
    }

    // Company name validation
    if(states?.type === "CompanyName"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Company name cannot be empty");
        }
    }

    // VATRegistrationNo name validation
    if(states?.type === "VATRegistrationNo"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("VAT registration no cannot be empty");
        }
    }
    
    
    // RegistrationNo name validation
    if(states?.type === "RegistrationNo"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Registration no cannot be empty");
        }
    }

    // Amount validation
    if(states?.type === "Amount"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Amount cannot be empty");
        }
    }

    // InCoterm validation
    if(states?.type === "InCoterm"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("In-Coterm cannot be empty");
        }
    }

    // Quantity validation
    if(states?.type === "Quantity"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Quantity cannot be empty");
        }
    }

    // ItemNumber validation
    if(states?.type === "ItemNumber"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Item number cannot be empty");
        }
    }

    // PI number validation
    if(states?.type === "PInumber"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("PI-number cannot be empty");
        }
    }

    // HSCode validation
    if(states?.type === "HSCode"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("HS-code cannot be empty");
        }
    }
};

export default CheckValid;